import { useStaticQuery, graphql } from "gatsby"
import { useMemo } from "react"

const useData = () =>
  useStaticQuery(graphql`
    query {
      pages: allMdx {
        nodes {
          slug
          frontmatter {
            title
            id
          }
        }
      }

      navigation: allNavigationYaml {
        nodes {
          id
          title
          inject
          categories {
            title
            items {
              id
              categories {
                items
                title
              }
            }
          }
        }
      }
    }
  `)

const getCategory = (category, pages, parent) => ({
  title: category.title,
  items: category.items
    .map(item => {
      const page = getPage(item, pages, parent)
      if (page) page.category = category.title
      return page
    })
    .filter(v => v),
})

const getPage = (route, pages, parent) => {
  const id = typeof route === "string" ? route : route.id
  const page = pages.find(({ frontmatter }) => frontmatter?.id === id)
  if (!page) {
    return null
  }

  const entity = {
    path: `/${page.slug}`,
    id: page.frontmatter.id,
    title: page.frontmatter.title,
    tab: route.title,
  }
  entity.parent = parent || entity
  entity.root = parent?.root || entity.parent
  entity.categories = route.categories?.map(item => getCategory(item, pages, entity)) || []
  return entity
}

const getNavigation = (routes, pages) => {
  return routes.map(route => getPage(route, pages, null)).filter(v => v)
}

const findArticle = (navigation, id) => {
  const findById = page => {
    const pageId = typeof page === "string" ? page : page.id
    if (pageId === id) return page

    const articles = page.categories?.flatMap(({ items }) => items) || []
    return articles.map(findById).filter(v => v)[0]
  }

  return navigation.map(findById).filter(v => v)[0]
}

const useMenu = id => {
  const { navigation, pages } = useData()

  return useMemo(() => {
    const routes = navigation.nodes.filter(node => !node.inject)
    const injectable = navigation.nodes.filter(node => node.inject)

    injectable.forEach(item => {
      const sub = findArticle(routes, item.inject)
      if (sub) Object.assign(sub, item, { id: item.inject })
    })

    const menu = getNavigation(routes, pages.nodes)
    const article = findArticle(menu, id) || menu[0]
    article.isActive = true

    return { menu, article }
  }, [navigation, pages, id])
}

export default useMenu
